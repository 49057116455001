import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";

export default function SmallCard({ project }) {
  return (
    <div className="items-center mx-2 snap-center">
      <div
        key={project.id}
        className="flex flex-nowrap w-64 shadow-md bg-white rounded-xl lg:rounded-b-none"
      >
        <Link
          to={"/shared/portfolio/" + project.slug}
          className="flex flex-row"
        >
          <div className="">
            <GatsbyImage
              image={getImage(project.smallCover)}
              alt="client logo"
              className="w-24 rounded-l-xl lg:rounded-bl-none"
            />
          </div>
          <div className="flex flex-col align-middle h-full justify-between px-2">
            <div className="mt-4 flex justify-center align-middle"></div>
            <div className=" w-full">
              <p className="text-sm text-gray-500 w-full line-clamp-5 italic">
                {project.shortTeaser}
              </p>
            </div>
            <div className="flex items-center justify-end uppercase text-blue-500 text-sm p-2 ">
              <span>Read</span>
              <ChevronRightIcon className="h-6 w-6" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
