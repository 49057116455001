import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout";
import SmallCard from "../../../components/smallCard";
import Client from "../../../components/client";
import { Helmet } from "react-helmet";

import { ChevronRightIcon } from "@heroicons/react/outline";

function Index({ data }) {
  const path =
    typeof location !== "undefined" && location.pathname
      ? location.pathname
      : "";
  return (
    <Layout location="/shared/portfolio/">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Portfolio</title>
        <meta name="description" content="Ole Lillebos Portfolio" />
        <html lang="en" amp />
        <link rel="canonical" href="https://www.olelillebo.com/portfolio/" />
      </Helmet>
      <div className="bg-gray-50 dark:bg-gray-900 dark:text-[#94a3b8] pt-16 -mt-16">
        <div className="hidden snap-x overflow-x-scroll py-4">
          {data.projects.nodes
            .filter((project) => project.teaser != null)
            .map((project) => (
              <div key={project.id + 100} className="flex">
                <SmallCard project={project} />
              </div>
            ))}
        </div>
        <div className="max-w-screen-xl mx-auto px-4 pt-6 flex justify-center flex-col items-center">
          <h2 className="text-lg lg:text-xl font-bold leading-snug text-gray-900 dark:text-white my-2">
            Portfolio
          </h2>
          <p className="text-base font-light max-w-screen-sm flex text-center">
            The list of cases below represents an excerpt of challenges I have
            phased throughout my career. With most of my projects under
            non-disclosure agreements, sensitive internal details such as
            outcome metrics have been left out or anonymized. All case
            descriptions have been reviewed and approved by representatives from
            the respective client.
          </p>
        </div>

        <div className="max-w-screen-xl mx-auto px-4 py-6">
          {data.projects.nodes
            .filter((project) => project.teaser != null)
            .map((project) => (
              <Link to={project.slug} key={project.id}>
                <div
                  key={project.id}
                  className="group mb-6 bg-white dark:bg-gray-800 shadow-md rounded-xl md:shadow-none md:max-w-screen-xl overflow-hidden md:mx-auto md:px-6 flex items-center w-full pb-4 md:py-4 flex-col-reverse md:flex-row hover:shadow-md"
                >
                  <div className="flex flex-col w-full md:w-2/5 px-4 md:px-0">
                    <Client
                      image={
                        project && project.client && project.client.logo
                          ? project.client.logo
                          : ""
                      }
                      title={
                        project && project.client && project.client.name
                          ? project.client.name
                          : ""
                      }
                      subtitle={
                        (project.period ? project.period : "") +
                        (project.periodTo ? " - " + project.periodTo : "")
                      }
                      variant={"medium"}
                    />
                    <h2 className="text-lg lg:text-xl font-bold lg:font-extrabold  leading-snug lg:leading-normal text-gray-900 dark:text-white xl:text-2xl my-4">
                      {project.teaser}
                    </h2>

                    <div className="flex justify-end md:justify-start">
                      <div className="flex items-center py-2 font-semibold text-gray-900 dark:text-white">
                        <span>Read case</span>
                        <ChevronRightIcon className="h-6 w-6 group-hover:animate-wiggle text-interaction" />
                      </div>
                    </div>
                  </div>
                  <div className="min-w-0 flex w-full md:w-3/5 h-auto md:pl-6 bg-gray-100 md:bg-transparent mb-4 lg:mb-0 dark:bg-gray-800">
                    <GatsbyImage
                      image={getImage(project.coverphoto)}
                      alt="client logo"
                      className="object-center rounded-t-xl md:rounded-xl w-full h-full " //drop-shadow-xl
                    />
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    projects: allContentfulProject(sort: { fields: period, order: DESC }) {
      nodes {
        id
        client {
          logo {
            gatsbyImageData(width: 400)
          }
          name
          employer {
            logo {
              gatsbyImageData(width: 400)
            }
          }
        }
        title
        slug
        teaser
        shortTeaser
        tags
        industry
        period(formatString: "MMM YYYY")
        periodTo(formatString: "MMM YYYY")
        role
        coverphoto {
          gatsbyImageData(height: 800, placeholder: BLURRED)
        }
        smallCover {
          gatsbyImageData(height: 800)
        }
      }
    }
  }
`;

export default Index;
