import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Client(props) {
  let titleStyle = "text-md text-black";
  let subtitleStyle = "text-md text-gray-700";
  let imageSize = "h-12 w-12";

  if (props.variant === "large") {
    titleStyle = "text-md text-gray-900 font-semibold dark:text-slate-200";
    subtitleStyle = "text-sm text-gray-500 dark:text-gray-400";
    imageSize = "h-12 w-12";
  } else if (props.variant === "medium") {
    titleStyle = "text-sm font-medium text-gray-900 dark:text-slate-200";
    subtitleStyle = "text-sm text-gray-400";
    imageSize = "h-10 w-10";
  } else if (props.variant === "xlarge") {
    titleStyle =
      "text-md lg:text-lg text-gray-600 font-semibold dark:text-slate-200";
    subtitleStyle = "text-sm text-gray-400";
    imageSize = "h-10 w-10 lg:h-12 lg:w-12";
  }

  return (
    <div className="flex-1 flex flex-row align-middle">
      <GatsbyImage
        image={props && props.image ? getImage(props.image) : ""}
        alt="client logo"
        className={imageSize + " shrink-0 flex rounded-full bg-white"}
      />
      <div className="flex pl-2 flex-col justify-center">
        {props && props.title ? (
          <p className={titleStyle}>{props.title}</p>
        ) : (
          ""
        )}
        {props && props.subtitle ? (
          <p className={subtitleStyle}>{props.subtitle}</p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
